.checkout-layout {
  margin: 4.9% 0;
  padding: 0 13%;

  @media (max-width: 1200px) {
    padding: 0 5%;
  }

  .basket-container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
    height: auto;
    overflow: hidden;

    @media (max-width: 768px) {
      height: 620px !important;
    }

    .checkout-basket-left {
      p,
      select,
      .date-picker input,
      .time-picker {
        font-size: 15px;
      }

      select,
      .date-picker input::placeholder,
      .time-picker {
        color: #b7b7b7 !important;
        padding-left: 20px !important;
      }

      select {
        color: #9b9b9b;
        background-color: white;
        transition: background-color 0.3s ease-in-out;
      }

      select,
      .date-picker input,
      .time-picker {
        width: 72%;
      }

      // @media (max-width: 767px) {
      //   select,
      //   .date-picker input,
      //   .time-picker {

      //   }
      // }
    }

    .checkout-basket-right {
      width: 100%;

      h4 {
        font-size: 100%;
        font-weight: 400;
      }

      h5,
      p {
        font-size: 15px;
        svg {
          font-size: 24px;
        }
      }

      .inline {
        svg {
          color: #ddd;
          font-size: 22px;
          cursor: pointer;
          transition: color 0.3s ease-in-out;
        }
      }
    }
  }

  .h4shporta {
    color: #ff5758;
    transition: color 0.3s ease-in-out;
  }
}

@media (max-width: 413px) {
  .checkout-layout {
    .basket-container {
      height: 670px !important;

      // p,
      // h4 {
      //   font-size: 18.5px !important;
      // }

      .checkout-btn {
        justify-content: center !important;
      }

      .h4shporta {
        margin-top: 5vh;
        text-align: center;
      }
    }
  }
}

.time-picker {
  .react-select__control {
    height: 38px;
  }

  .react-select__menu {
    margin-top: 0;
  }
}

.checkout2-layout {
  margin: 2% 0 4.9%;
  padding: 0 12% 0 8.5%;

  .top-link {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-size: max(12px, 0.8vw);
    a {
      margin-right: 5px;
      color: #ff5758;
    }
  }

  .checkout2-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 4.5%;
    .basket-container {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
      width: 56.5%;
      form {
        margin: max(15.5px, 1.5vw) 0;
        .input-container {
          width: 433px;
          .image_upload_container {
            border: 1px solid #c7c7c7;
            border-radius: 10px;
            // height: max(30px, 1.8vw);
            padding: 3px max(10px, 0.2vw);
            margin-bottom: max(12.5px, 1vw);

          }
          input, textarea {
            width: 100%;
            border: 1px solid #c7c7c7;
            border-radius: 10px;
            height: max(30px, 1.8vw);
            padding: 3px max(10px, 0.2vw);
            margin-bottom: max(12.5px, 1vw);
            &:focus-within{
              border: 1px solid #ff5758;
            }
  
          }
          p {
            color: #6e7074;
            font-weight: 400;
            font-size: max(13px, 0.805vw);
          }
        }
      }
      .input-container textarea{
        height: 100px !important;
      }
    }
    .order-content {
      width: 27.5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h4 {
        text-align: center;
      }

      .dateAndTime {
        display: flex;
        // flex-wrap: wrap;
        // // align-items: center;
        // justify-content: center;
        color: #ff5758;
        margin: max(18.5px, 2.2vw) 0 0;

        h1 {
          font-weight: 700;
        }

        .line {
          height: 50px;
          width: 1px;
          background: #ff5758;
          margin: 0 10px;
        }

        .date {
          display: flex;
          flex-direction: column;
          h6 {
            font-weight: 700;
          }
          h6,
          p {
            font-size: max(13px, 0.805vw);
          }
        }
      }

      a {
        color: #4682b4;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: underline;
        font-weight: 400;
        margin-bottom: max(18.5px, 2.2vw);
        font-size: max(13px, 0.805vw);
      }

      .details {
        h5 {
          font-weight: 700;
        }
        h5,
        p {
          font-size: max(13px, 0.805vw);
        }
        p {
          color: #6e7074;
        }
      }
    }

    .basket-container,
    .order-content {
      h4 {
        font-weight: 700;
        font-size: max(16px, 1.1vw);
      }
    }
  }
}
.chooseStaff {
  width: 30%;
  // width: 450px;
  // margin: 0 60px;
  .time-picker {
    .time-select {
      .busySlot {
        color: #ff5758;
      }
      .available {
        // color: rgb(47, 150, 47);
      }
    }
  }
}
.chooseTermin {
  // width: 550px;
  // margin: 0 60px;
}
@media (max-width: 1275px) {
  .checkout2-layout {
    padding: 0 8.5%;
    .checkout2-inner {
      .order-content {
        width: 33%;
      }
    }
  }
}

@media (max-width: 1020px) {
  .checkout2-layout {
    .checkout2-inner {
      .basket-container {
        form {
          .input-container {
            width: 100%;
          }
        }
      }

      .order-content {
        width: 38%;
      }
    }
  }
}

@media (max-width: 767px) {
  .checkout2-layout {
    .checkout2-inner {
      flex-direction: column;
      .basket-container {
        width: 100%;
      }
      .order-content {
        width: 65%;
        margin: max(20.5px, 3.5vw) auto 0;
      }
    }
  }
}

@media (max-width: 562px) {
  .checkout2-layout {
    .checkout2-inner {
      .order-content {
        width: 85%;
      }
    }
  }
}

@media (max-width: 403px) {
  .checkout2-layout {
    .checkout2-inner {
      .order-content {
        width: 100%;
      }
    }
  }
}

.checkout3-layout {
  .check {
    content: url("../../assets/images/check.svg");
    height: max(18px, 1.5vw);
    filter: invert(44%) sepia(95%) saturate(811%) hue-rotate(320deg) brightness(99%) contrast(117%);
  }

  .checkout3-banner {
    padding: max(75px, 6%) 9.7%;
    background: #ff5758;
    color: #fff;
    h1 {
      font-size: max(20px, 1.5vw);
      font-weight: 700;
    }
  }

  .checkout-affirmation {
    padding: max(17px, 1.4vw) 9.7%;
    background: #dbdbdb;
    .inline {
      display: flex;
      .check,
      .back {
        margin-bottom: max(10px, 0.85vw);
        margin-right: max(5px, 0.6vw);
      }

      .back {
        content: url("../../assets/images/arrow-left.svg");
        height: max(18px, 1.5vw);
        filter: invert(44%) sepia(95%) saturate(811%) hue-rotate(320deg) brightness(99%) contrast(117%);
      }

      p {
        font-size: max(13px, 0.95vw);
        color: #515a58;
      }

      b {
        font-weight: bolder !important;
      }
    }
  }

  .checkout-details {
    background: #f7f9fa;
    padding: max(17px, 1.4vw) 9.7%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 900px) {
      padding: max(17px, 1.4vw) 2.7%;
    }
    .details-wrapper {
      width: 50%;
      p {
        font-size: max(16px, 1.2vw);
        color: #6e7074;
      }
      .info-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: max(20px, 1.4vw) 0 max(20px, 2vw);
        border-bottom: 1px solid #d5d6d7;
        .info {
          width: 40%;
          .dateAndTime {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: max(18px, 1.5vw) 0;
            h1 {
              font-size: max(30px, 2.5vw);
            }
            .date {
              display: flex;
              flex-direction: column;
              h6 {
                font-weight: 700;
              }
              h6,
              p {
                font-size: max(13px, 0.805vw);
              }
            }
          }
          .details {
            h5 {
              font-weight: 700;
            }
            h5,
            p {
              font-size: max(13px, 0.805vw);
            }
            p {
              color: #6e7074;
            }
          }
        }
        .slider {
          width: 55%;
        }
      }

      .location-row {
        display: flex;
        justify-content: space-between;
        padding: max(17px, 2vw) 0 max(17px, 1.4vw);
        .salon {
          width: 50%;
          h2 {
            font-size: max(16px, 1.2vw);
          }
          p {
            font-size: max(13px, 0.805vw);
            color: #6e7074;
          }
        }
        .map-wrapper {
          width: 61.5%;
          height: 183px;
        }
      }
    }

    .card-wrapper {
      width: 50%;
      position: relative;
      .card {
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
        border-radius: 10px;
        width: 50%;
        padding: max(17px, 1.8vw) max(25px, 2vw);
        position: absolute;
        top: -16.5%;
        right: 26%;

        // @media (max-width: 1500px) {
        //   width: 56%;
        //   right: 22%;
        // }

        @media (max-width: 1300px) {
          width: 62%;
          right: 12%;
        }

        @media (max-width: 1200px) {
          width: 70%;
          right: 0;
          top: -13%;
        }

        .check {
          margin-bottom: max(17px, 1.5vw);
        }

        .text {
          margin-bottom: max(15px, 1vw);
        }

        h5 {
          font-size: max(13px, 0.805vw);
          font-weight: 700;
        }

        p {
          font-size: max(13px, 0.7vw);
          color: #6e7074;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .checkout3-layout {
    .checkout-details {
      .details-wrapper {
        p {
          text-align: center;
        }
        .info-row .info {
          .dateAndTime {
            flex-direction: column;
          }
          .dateAndTime,
          .details {
            text-align: center;
          }
        }
        .location-row {
          align-items: center;
          .salon {
            text-align: center;
          }
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .checkout3-layout {
    .checkout-details {
      .card-wrapper {
        margin: max(17px, 2.8vw) 0;
        .card {
          position: relative;
          top: 0;
          right: 0;
          margin: 0 auto;
          // text-align: center;
          width: 80%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .checkout3-layout {
    .checkout3-banner {
      text-align: center;
    }

    .checkout-details {
      padding: max(17px, 1.4vw) 9.7%;
      flex-direction: column;
      .details-wrapper {
        width: 100%;
      }
      .card-wrapper {
        width: 100%;
        .card {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .checkout3-layout {
    .checkout-details {
      .details-wrapper {
        .info-row {
          flex-direction: column;
          .info,
          .slider {
            width: 100%;
          }
          .info {
            margin-bottom: max(16px, 1.2vw);
          }
        }

        .location-row {
          flex-direction: column;
          .salon,
          .map-wrapper {
            width: 100%;
          }
          .salon {
            margin-bottom: max(16px, 1.2vw);
          }
        }
      }
    }
  }
}

.checkout-layout {
  margin: 4.9% 0;
  padding: 0 13%;
  .elements-rows {
    display: inline;
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 30px !important;
    .appointments {
      width: 25%;
    }
    .chooseOption {
      width: 100%;
      display: flex;

      .chooseDate {
        // width: 100% !important;
        // margin: 0;
        .customDatePicker {
          width: 100%;
          height: 40px !important;
          padding: 10px;
          border: 1px solid rgb(218, 211, 211);
          border-radius: 3px;
        }
        p {
          margin-bottom: 8px;
        }
      }
      .chooseStaff {
        // width: 100%;
        // margin: 0 30px;
        p {
          width: 300px;
          margin-bottom: 8px;
        }
      }
      .chooseTermin {
        // width: 100%;
        // margin: 0 30px;
        p {
          width: 300px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .basket-container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
    height: auto !important;
    overflow: hidden;
    padding: 30px;

    .basket {
      color: #ff5758;
      transition: color 0.3s ease-in-out;
      margin-bottom: 20px;
    }
    .closeButton {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // height: auto;
      margin: auto;
    }
    .x-button {
      margin-top: 30px;

      font-size: 20px !important;
      color: #939090;
    }
    @media (max-width: 768px) {
      height: auto;
    }
    @media (max-width: 1428px) {
      .appointments {
        width: 180px !important;
      }
    }
    @media (max-width: 1700px) {
      .elements-rows {
        flex-direction: column;
      }

      .appointments {
        width: 100% !important;
        align-items: center;
        margin: 30px 0;
        justify-content: center;
        margin: 10px !important;
      }
      .appointments h5 {
        font-size: 22px !important;
        text-align: center !important;
        justify-content: center !important;
      }
      .chooseOption {
        width: 100%;
        align-items: center;
        .chooseStaff,
        .chooseDate,
        .chooseTermin {
          // width: 100% !important;
        }
      }

      .appointments p {
        text-align: center;
      }
      .closeButton {
        font-size: 20px !important;
        color: #939090;
        margin: 20px 0;
      }
    }

    @media (max-width: 1500px) {
      .chooseOption {
        width: 100%;
        // display: flex;
        flex-wrap: wrap;
        .chooseStaff,
        .chooseDate {
          // width: 45% !important;
          p {
            margin-bottom: 10px !important;
          }
        }
        .chooseTermin {
          // width: 100%;
          // margin-top: 10px;
          p {
            margin-bottom: 10px !important;
          }
        }
      }
    }

    // @media (max-width: 1100px) {
    //   .chooseOption {
    //     width: 100%;
    //     flex-direction: row;
    //     flex-wrap: wrap;

    //     .chooseStaff {
    //       // width: 100% !important;
    //       margin: 0 !important;
    //     }
    //     .chooseDate {
    //       // width: 100% !important;
    //       // margin: 15px 0;
    //       .customDatePicker {
    //         width: 100%;
    //         height: 40px;
    //       }
    //     }
    //     .chooseTermin {
    //       // margin: 0 !important;
    //       .time-picker {
    //         .time {
    //           width: 100%;
    //         }
    //       }
    //     }
    //   }
    // }
    
    @media (max-width: 500px) {
      .basket {
        margin-top: 20px;
      }
      .appointments h5 {
        width: 100% !important;
        text-align: center !important;
      }

      .appointments p {
        text-align: center;
        font-size: 15px !important;
        margin-bottom: 10px !important;
      }
      .chooseOption{
        .row{
          .chooseStaff{
            width: 100% !important ;
            margin-bottom: 10px;
          }
        }
      }
      
    }
    @media (max-width: 750px) {
     
          .worker{
            width: 100% !important;
            margin-bottom: 10px;
          }
          .date{
            width: 50% !important;
          }
          .time{
            width: 50% !important;
          }
          .w-auto{
            margin:0 auto;
            margin-bottom: 10px;
          }
    
    }
    
  }
}
