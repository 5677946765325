.select_one_language{
  max-width: 6.7rem !important;
  min-width: 6.7rem !important;
}
.dropdown{
  box-shadow: none !important;
}
.header-layout {
  z-index: 2000;
  padding: max(2.55vw, 5px) 4.5% max(0.15vw, 5px);
  .header-logo {
    .logo {
      content: url("../../assets/images/termini_beauty-logo.webp");
      height: max(40px, 3vw);
    }
  }
  .buttons {
    .login,
    .logout,
    .profile {
      svg {
        color: #36b2b0;
        font-size: 26px;
      }
      span {
        cursor: pointer;
        color: #000;
        transition: 0.3s ease-in-out;
        font-size: max(12.5px, 0.9vw);
        &:hover {
          color: #36b2b0;
        }
      }
    }

    .business-button {
      .business-icon {
        content: url("../../assets/images/business-icon.svg");
        filter: invert(59%) sepia(70%) saturate(401%) hue-rotate(130deg) brightness(90%) contrast(85%);
        height: 22.5px;
      }
      span {
        cursor: pointer;
        color: #000;
        transition: 0.3s ease-in-out;
        font-size: max(12.5px, 0.9vw);
        &:hover {
          color: #36b2b0;
        }
      }
    }
  }

  .header-links {
    a {
      color: #525b6986;
      margin-left: 15px;
    }
    .more-link {
      .header-dropdown {
        display: none;
        .inner-header-dropdown {
          display: flex;
          flex-direction: column;
          background-color: rgb(239, 242, 245);
          position: fixed;
          padding: max(15px, 0.5vw) max(20px, 1vw);
          z-index: 3000;
        }
      }
    }
    .more-link:hover {
      .header-dropdown {
        display: flex;
      }
    }
    .costumHeaderButton {
      color: rgba(82, 91, 105, 0.5254901961);
      margin-left: 15px;
      cursor: pointer;
    }
    .dropdown-wrapper {
      // position: absolute;
      width: 80%;
      left: 103%;
      top: 0;
      z-index: 1001 !important;

      .dropdown {
        border-radius: 10px;
        background: #eff2f5;
        padding: max(10px, 1vw) max(22px, 1vw);
        position: relative;
        width: 100%;

        ul {
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          gap: 5px;
          max-height: 170px;
          overflow: auto;
          li {
            display: flex;
            text-transform: capitalize;
            align-items: flex-start;
            font-size: max(11px, 0.75vw);
          }
        }
      }

      .dropdown::before {
        content: "";
        position: absolute;
        border-right: 20px solid #eff2f5;
        border-left: 20px solid transparent;
        border-top: 20px solid #eff2f5;
        border-bottom: 5px solid transparent;
        left: -20px;
        top: 10px;
      }
    }
  }

  .mobile-header {
    display: none;
  }
}

@media (max-width: 767px) {
  .header-layout {
    height: 0;

    .header-logo,
    .buttons,
    .header-links {
      display: none !important;
    }

    .mobile-header {
      display: block;
    }
  }
}

.mobile-toolbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  display: flex;
  z-index: 2000;
  justify-content: space-between;
  align-items: center;
  padding: 15px 7.5% 10px;
  // padding: max(2.55vw, 5px) 4.5% max(0.15vw, 5px);

  .mobile-logo-row {
    display: flex;
    align-items: center;

    .mobile-logo {
      content: url("../../assets/images/termini_beauty-logo.webp");
      height: 37.5px;
    }
  }

  button {
    border: 0;
    background-color: transparent;
    font-size: 30px;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mobile-toolbar {
  background-color: rgb(239, 242, 245);
  transition: 0.2s ease;

  button {
    color: #111;
  }
}

.mobile-sidebar {
  background-color: rgb(239, 242, 245);
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  max-width: 77.5%;
  z-index: 2200;
  overflow-y: auto;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
  -webkit-animation-name: mobile-header-scroll;
  animation-name: mobile-header-scroll;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  min-height: 100vh;
  height: 100%;

  .sidebar-logo-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    border-bottom: 1px solid #aaa;
    padding: 0 15px;
    a {
      display: flex;
      align-items: center;
      .mobile-logo {
        content: url("../../assets/images/termini_beauty-logo.webp");
        height: 35px;
      }
    }
    button {
      border: 0;
      background: none;
      font-size: 30px;
      color: #ff5758;
      cursor: pointer;
    }
  }

  .mobile-header-links {
    .header-sidebar-link {
      display: flex;
      justify-content: space-between;
      padding: 16px 20px 15px;
      border-bottom: 1px solid #aaa;
      border-left: 4px solid transparent;
      a {
        font-size: 16.5px;
        width: 160px;
        display: flex;
        align-items: center;
        color: #444;
        svg {
          color: #ff5758;
          margin-right: 10px;
          font-size: 20px;
        }
      }
      &:hover {
        border-left: 4px solid #ff5758;
      }
    }
    .dropdown-wrapper {
      position: absolute;
      width: 80%;
      left: 103%;
      top: 0;
      z-index: 1001 !important;

      .dropdown {
        border-radius: 10px;
        background: #eff2f5;
        padding: max(10px, 1vw) max(22px, 1vw);
        position: relative;
        width: 100%;

        ul {
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          gap: 5px;
          max-height: 170px;
          overflow: auto;
          li {
            display: flex;
            text-transform: capitalize;
            align-items: flex-start;
            font-size: max(11px, 0.75vw);
          }
        }
      }

      .dropdown::before {
        content: "";
        position: absolute;
        border-right: 20px solid #eff2f5;
        border-left: 20px solid transparent;
        border-top: 20px solid #eff2f5;
        border-bottom: 5px solid transparent;
        left: -20px;
        top: 10px;
      }
    }
    .dropdown-arrow {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      font-size: 22.5px;
      cursor: pointer;
    }
  }

  .dropdown-links {
    margin: 0;
    padding: 3px 0 3px 50px;
    border-bottom: 1px solid #aaa;
    a {
      color: #555;
      font-size: 15.5px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 7px 0;
      &:hover {
        color: #ff5758;
      }
    }
  }

  .mobile-header-buttons {
    display: flex;
    align-items: center;
    justify-content: center !important;
    padding: 11px;
    --bs-gutter-x: 0 !important;
    .login,
    .logout,
    .profile {
      svg {
        color: #36b2b0;
        font-size: 26px;
      }
      span {
        cursor: pointer;
        color: #000;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #36b2b0;
        }
      }
    }

    .business-button {
      .business-icon {
        content: url("../../assets/images/business-icon.svg");
        filter: invert(59%) sepia(70%) saturate(401%) hue-rotate(130deg) brightness(90%) contrast(85%);
        height: 22.5px;
      }
      span {
        cursor: pointer;
        color: #000;
        transition: 0.3s ease-in-out;
        //   font-size: max(12.5px,.9vw);
        &:hover {
          color: #36b2b0;
        }
      }
    }
  }
}

.header-backdrop {
  width: 100%;
  min-height: 100vh;
  height: stretch;
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2100;
}

@-webkit-keyframes mobile-header-scroll {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes mobile-header-scroll {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
