.modal-layout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998 !important;

  .modal-container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
    padding: max(15px, 1vw) max(20px, 1.5vw) max(20px, 1.5vw);
    width: 25%;
    position: relative;

    .close-btn {
      text-align: end;
      position: absolute;
      top: -4%;
      right: -3%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: max(22px, 1.5vw);
      width: max(22px, 1.5vw);
      background-color: #ff5758;
      cursor: pointer;
      button {
        background-color: transparent;
        border: none;
        font-size: max(25px, 1.8vw);
        cursor: pointer;
      }
    }
    .close-btn-signup {
      text-align: end;
      position: absolute;
      top: -2%;
      right: -3%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: max(22px, 1.5vw);
      width: max(22px, 1.5vw);
      background-color: #ff5758;
      cursor: pointer;
      button {
        background-color: transparent;
        border: none;
        font-size: max(25px, 1.8vw);
        cursor: pointer;
      }
    }

    .modal-title {
      text-align: center;
      h3 {
        font-size: max(20px, 1.5vw);
      }
      p {
        font-size: max(14px, 0.85vw);
        a {
          color: #ff5758;
          cursor: pointer;
        }
      }
    }

    form {
      margin-top: 15px;
      .input-container {
        margin-bottom: max(12.5px, 0vw);
        p {
          font-size: max(13px, 0.825vw);
          margin-bottom: max(7px, 0.5vw);
          font-weight: 600;
        }
        .inner-input-container {
          align-items: center;
          border: 1px solid #e2e2e2;
          border-radius: 10px;
          display: flex;
          padding: 0 max(10px, 0.5vw);
          &:focus-within {
            span {
              filter: invert(48%) sepia(98%) saturate(2134%) hue-rotate(324deg) brightness(99%) contrast(104%);
            }
            border: 1px solid #ff5758;
            ::-webkit-input-placeholder {
              color: #ff5758;
            }
          }
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            height: max(17px, 1.1vw);
            filter: invert(33%) sepia(0%) saturate(2616%) hue-rotate(299deg) brightness(93%) contrast(85%);
          }
          input {
            background: none;
            border: 0;
            border-radius: 10px;
            color: #3a3a3a;
            flex: 1 1;
            font-size: max(12px, 0.725vw);
            height: max(35px, 2vw);
            padding: 0 max(6px, 0.35vw);
          }

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            -webkit-transition: background-color 5000s ease-in-out 0s;
            -webkit-box-shadow: 0 0 0px 1000px transparent inset;
            box-shadow: 0 0 0px 1000px transparent inset;
            -webkit-text-fill-color: #3a3a3a !important;
          }
          .user-icon {
            content: url("../../assets/images/user-icon.svg");
          }
          .phone-icon {
            content: url("../../assets/images/phone.svg");
          }
          .lock_icon {
            content: url("../../assets/images/lock-icon.svg");
          }
          .email-icon {
            content: url("../../assets/images/email-icon.svg");
          }
          .phone-icon {
            content: url("../../assets/images/phone_icon.svg");
          }
        }
      }

      button {
        padding: max(12.5px, 0.5vw);
        width: 100%;
      }
    }
  }
}

@media (max-width: 1200px) {
  .modal-layout {
    .modal-container {
      width: 40%;
    }
  }
}

@media (max-width: 767px) {
  .modal-layout {
    .modal-container {
      width: 60%;
    }
  }
}

@media (max-width: 540px) {
  .modal-layout {
    .modal-container {
      width: 90%;
    }
  }
}
