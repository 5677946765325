.homepage-layout {
    margin: 2% 4.5% 0;
    position: relative;

    .homepage-banner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        position: relative;
        background: url("../../assets/images/homepage-banner.webp");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: max(50px, 8.7%) 7.5%;
        margin-bottom: 2.5%;

        @media (max-width: 1425px) {
            padding: max(50px, 16%) 7.5%;
        }

        .info {
            position: relative;
            border-radius: 10px;
            padding: max(15px, 1.5vw) max(25px, 2.5vw);
            background: #ffffff;
            width: 390px;
            height: 360px;
            z-index: 1000 !important;

            @media (max-width: 1669px) {
                height: 335px;
            }

            @media (max-width: 1400px) {
                height: 310px;
            }

            @media (max-width: 767px) {
                height: 300px;
            }

            .tabs-container {
                .react-tabs {
                    .react-tabs__tab-list {
                        padding: 0;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between !important;
                    }
                }

                .react-tabs .react-tabs__tab--selected .tab-wrapper {
                    .icon {
                        filter: invert(48%) sepia(98%) saturate(2134%) hue-rotate(324deg) brightness(99%) contrast(104%);
                    }
                    h5 {
                        color: #ff5758;
                        text-decoration: underline;
                    }
                }

                .tab-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    .icon {
                        height: max(25px, 1.8vw);
                        margin-bottom: max(5px, 0.2vw);
                    }
                    .icon1 {
                        content: url("../../assets/images/scissors.svg");
                    }

                    .icon2 {
                        content: url("../../assets/images/chair.svg");
                    }

                    h5 {
                        font-size: max(16px, 0.96vw);
                        font-weight: bold;
                    }
                }

                .first-tab-margin {
                    margin: 33px 0 0 0 !important;
                }

                .tab-panel-wrapper {
                    margin: 20px 0;
                    .input-wrapper {
                        position: relative;
                        .input-container {
                            display: flex;
                            align-items: center;
                            border: 1px solid #c7c7c7;
                            border-radius: 10px;
                            padding: 0px max(3px, 0.2vw);
                            margin-bottom: max(12.5px, 1vw);
                            &:focus-within {
                                span {
                                    filter: invert(48%) sepia(98%) saturate(2134%) hue-rotate(324deg) brightness(99%)
                                        contrast(104%);
                                }
                                border: 1px solid #ff5758;
                                ::-webkit-input-placeholder {
                                    color: #ff5758;
                                }
                            }

                            span {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin: 0px max(3px, 0.2vw);
                                height: max(17px, 1.4vw);
                                // filter: invert(96%) sepia(0%) saturate(878%) hue-rotate(69deg) brightness(91%) contrast(98%);
                                // filter: invert(76%) sepia(5%) saturate(421%) hue-rotate(121deg) brightness(95%) contrast(93%);
                                filter: invert(100%) sepia(8%) saturate(148%) hue-rotate(54deg) brightness(60%)
                                    contrast(88%);
                            }

                            .span1 {
                                content: url("../../assets/images/search-icon.svg");
                            }

                            .span2 {
                                content: url("../../assets/images/location-icon.svg");
                            }

                            .span3 {
                                content: url("../../assets/images/calendar-icon.svg");
                                filter: invert(90%) sepia(4%) saturate(121%) hue-rotate(219deg) brightness(87%)
                                    contrast(87%);
                                // filter: invert(76%) sepia(5%) saturate(421%) hue-rotate(121deg) brightness(95%) contrast(93%);
                            }

                            input {
                                background: none;
                                border: 0;
                                flex: 1 1 0%;
                                font-size: max(12px, 0.825vw);
                                height: max(30px, 1.8vw);
                                padding: 0px max(3px, 0.2vw);
                            }

                            input::placeholder {
                                color: #c1c0c3;
                            }
                        }

                        .dropdown-wrapper {
                            position: absolute;
                            width: 80%;
                            left: 103%;
                            top: 0;
                            z-index: 1001 !important;

                            .dropdown {
                                border-radius: 10px;
                                background: #eff2f5;
                                padding: max(10px, 1vw) max(22px, 1vw);
                                position: relative;
                                width: 100%;

                                ul {
                                    margin: 0;
                                    padding: 0;
                                    display: flex;
                                    flex-direction: column;
                                    gap: 5px;
                                    max-height: 170px;
                                    overflow: auto;
                                    li {
                                        display: flex;
                                        text-transform: capitalize;
                                        align-items: flex-start;
                                        font-size: max(11px, 0.75vw);
                                    }
                                }
                            }

                            .dropdown::before {
                                content: "";
                                position: absolute;
                                border-right: 20px solid #eff2f5;
                                border-left: 20px solid transparent;
                                border-top: 20px solid #eff2f5;
                                border-bottom: 5px solid transparent;
                                left: -20px;
                                top: 10px;
                            }
                        }
                    }

                    .homepage_search_input {
                        padding: max(12.5px, 0.5vw);
                        width: 100%;
                    }
                }
            }
        }

        .text {
            color: #ffffff;
            width: 33%;

            h1 {
                font-size: max(45.5px, 3.75vw);
                font-weight: 700;
                line-height: 1.3;
            }
            p {
                font-size: max(20px, 1.1vw);
                font-weight: bold;
                margin-top: max(25px, 1.7vw);
            }
        }
    }
}

.salons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    gap: max(15px, 1.4vw);

    .salon-content {
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
        padding: max(17.5px, 1.5vw);
        width: 23%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        // height: 596px;

        .first-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .slider {
                width: 50%;
            }
            .info {
                width: 45%;
                h5 {
                    font-weight: 700;
                    font-size: max(15px, 0.97vw);
                }
                .rating {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center !important;
                    gap: 1px;
                    color: #ffae00;
                    margin: max(9px, 0.75vw) 0;
                    font-size: max(15px, 0.9vw);

                    .rating-number {
                        margin-right: 5px;
                        font-weight: 700;
                    }

                    .star-icon {
                        display: flex;
                        align-items: center;
                        margin-bottom: 4.5px;
                    }
                }

                p {
                    color: #8d9095;
                    font-size: max(12.5px, 0.8vw);
                }
            }
        }

        .second-row {
            border-top: 1px solid #dbdbdb;
            border-bottom: 1px solid #dbdbdb;
            margin: max(12.5px, 0.8vw) 0;
            padding: max(15.5px, 1.3vw) 0;
            display: flex;
            flex-direction: column;
            flex: 1 1;
            gap: max(12.5px, 0.8vw);

            .treatment-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media (max-width: 355px) {
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                }

                .treatment-info {
                    font-size: max(12.5px, 0.8vw);
                    h6 {
                        font-weight: 700;
                    }
                    p {
                        color: #565658;
                    }
                }

                .choose-btn {
                    margin-right: 7.5%;
                }
            }
        }

        .third-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: max(10.5px, 0.5vw) 0;

            a {
                color: #4682b4;
                font-weight: 700;
                font-size: max(15px, 0.97vw);
            }

            .arrow-down {
                content: url("../../assets/images/arrow-down.svg");
                filter: invert(43%) sepia(91%) saturate(322%) hue-rotate(164deg) brightness(92%) contrast(83%);
            }
        }
    }
}

@media (max-width: 880px) {
    .salons {
        gap: max(25px, 1.4vw);
        .salon-content {
            width: 48%;
            .first-row {
                flex-direction: column-reverse;
                gap: 15px;
                .slider,
                .info {
                    width: 100%;
                }
                .info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .salons {
        .salon-content {
            width: 80%;
            margin: 0 auto;
            .first-row {
                display: flex;
                flex-direction: row;
                .slider {
                    width: 50%;
                }
                .info {
                    width: 45%;
                }
            }
        }
    }
}
@media (max-width: 500px) {
    .salons {
        .salon-content {
            width: 95%;
            .first-row {
                flex-direction: column-reverse;
                gap: 15px;
                .slider,
                .info {
                    width: 100%;
                }
                .info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }

            .second-row {
                .treatment-wrapper {
                    .choose-btn {
                        margin-right: 0;
                    }
                }
            }

            .third-row {
                .arrow-down {
                    margin-right: 0;
                }
            }
        }
    }
}
@media (max-width: 1664px) {
    .homepage-layout {
        .homepage-banner {
            margin-bottom: 5.5%;
        }
    }
}

@media (max-width: 1090px) {
    .homepage-layout {
        .homepage-banner {
            padding: max(50px, 21%) 7.5%;
            margin-bottom: 6.5%;
        }
    }
}

@media (max-width: 900px) {
    .homepage-layout {
        .homepage-banner {
            margin-bottom: 18.5%;
            .info {
                width: 348px;
            }
        }
    }
}

@media (max-width: 767px) {
    .homepage-layout {
        .homepage-banner {
            flex-direction: column;
            gap: 60px;
            .text {
                width: 100%;
                text-align: center;
            }
            .info {
                width: 60%;
                .dropdown-wrapper {
                    width: 60% !important;
                    left: 98% !important;
                }
            }
        }
    }
}

@media (max-width: 650px) {
    .homepage-layout {
        .homepage-banner {
            .info {
                width: 70%;
                .dropdown-wrapper {
                    width: 60% !important;
                    left: 85% !important;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .homepage-layout {
        .homepage-banner {
            .info {
                width: 90%;
                .dropdown-wrapper {
                    width: 65% !important;
                    left: 60% !important;
                }
            }
        }
    }
}

@media (max-width: 340px) {
    .homepage-layout {
        .homepage-banner {
            .info {
                width: 90%;
                .dropdown-wrapper {
                    width: 77% !important;
                    left: 50% !important;
                }
            }
        }
    }
}
