.salon-layout {
  margin: 2% 4.5% 0;
  position: relative;

  .salon-inner {
    padding: max(50px, 4.7%) 7.5%;
    background-color: #f7f9fa;

    .banner {
      margin-bottom: max(13.5px, 1.2vw);
      h1 {
        font-size: max(22.5px, 2.1vw);
        font-weight: 400;
      }

      .info {
        .rating {
          display: flex;
          flex-wrap: wrap;
          align-items: center !important;
          gap: 1px;
          color: #ffae00;
          margin: max(9px, 0.75vw) 0;

          span {
            margin-right: 7px;
            font-weight: 700;
            font-size: max(15px, 1vw);
          }

          .star-icon {
            display: flex;
            align-items: center;
            margin-bottom: 4.5px;
            font-size: max(15px, 1.5vw);
          }
        }

        .inline {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            color: #6e7074;
            font-size: max(13px, 0.805vw);
          }
        }
      }

      .slider-wrapper {
        margin: max(10px, 0.95vw) 0;
        .slideshow-container {
          .no-image-placeholder {
            height: 305px !important;
          }
          .react-slideshow-wrapper {
            .slider-img {
              width: 100%;
              height: 305px !important;
              cursor: pointer;
              position: relative;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .slider-text {
                position: absolute;
                z-index: 111;
                bottom: 0;
                width: 100%;
                min-height: 18px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: hsl(0, 0%, 100%, 0.7);
                padding: max(8.5px, 0.55vw) max(10.5px, 0.65vw);
                transition: 0.3s ease-in-out;
                opacity: 0;
                h5 {
                  margin: 0;
                  font-size: max(13px, 0.85vw);
                }
                span {
                  font-size: max(13px, 0.66vw);
                }
              }
            }
          }

          .react-slideshow-container {
            button {
              background: none;
              width: 80px;
              height: 80px;
              transition: 0.3s ease-in-out;
              svg {
                fill: #ffffff8d;
                width: max(27.5px, 2.2vw);
                height: max(27.5px, 2.2vw);
              }
              opacity: 0;
            }
            button:hover,
            button:focus {
              background: none;
              svg {
                fill: #ffffff;
              }
            }
          }
        }

        .slideshow-container:hover {
          .slider-text {
            opacity: 1 !important;
          }
          .react-slideshow-container {
            button {
              opacity: 1;
            }
          }
        }
      }

      .location-schedule {
        display: flex;
        flex-direction: column;
        gap: max(8.5px, 0.7vw);
        .address,
        .schedule {
          display: flex;
          align-items: center;

          span {
            font-size: max(12px, 0.7vw);
            margin-right: 5px;
          }

          p {
            color: #6e7074;
            font-size: max(13px, 0.805vw);
          }
        }
      }
    }

    .popular-services {
      // border: 1px solid red;
      border-top: 3px solid #ff5758;
      display: flex;
      align-items: baseline;
      padding: max(17.5px, 1.8vw) 0;

      .left {
        // border: 1px solid green;
        width: 25%;

        h5 {
          font-size: max(15px, 0.97vw);
          font-weight: 700;
        }
      }

      .services {
        // border: 1px solid blue;
        width: 75%;
        display: flex;
        flex-direction: column;
        gap: max(12.5px, 0.8vw);
        .treatment-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 10px;
          border-bottom: 1px solid #dbdbdb;
          .treatment-info {
            font-size: max(12.5px, 0.8vw);
            h6 {
              font-weight: 700;
              font-size: max(12.5px, 0.8vw);
            }
            p {
              color: #565658;
            }
          }

          .choose-btn {
            margin-right: 7.5%;
            cursor: pointer;
          }
        }
      }
    }

    .checkout-btn-wrapper {
      position: relative;

      .proceed-to-checkout {
        // border: 1px solid red;
        width: 210px;
        background-color: #fff;
        position: fixed;
        top: 370px;
        right: -200px;
        z-index: 1002;

        .proceed-to-checkout-btn {
          position: absolute;
          top: 0;
          left: -39px;
          color: #ff5758;
          border-right: 1px solid #ff5758;
          background-color: #fff;
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
          cursor: pointer;
          padding: max(6px, 0.4vw);
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            animation: rotate 2s linear infinite;
            font-size: max(18.5px, 1.35vw);
          }

          @keyframes rotate {
            100% {
              transform: rotate(360deg);
            }
          }
        }

        a {
          color: #ff5758;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          text-align: center;
          padding: max(6px, 0.4vw);
          font-size: max(12.5px, 0.9vw);
        }
      }

      .proceed-to-checkout.active {
        right: 0;
      }
    }

    .browse-services {
      border-top: 3px solid #ff5758;
      border-bottom: 3px solid #ff5758;
      padding: max(17.5px, 1.8vw) 0;

      h5 {
        font-size: max(14px, 1vw);
        font-weight: 700;
        margin-bottom: max(17.5px, 2vw) !important;
      }

      .tabs-container {
        .react-tabs {
          display: flex;
          flex-direction: column;
          // align-items: center;
          justify-content: start;

          .react-tabs__tab-list {
            border: 1px solid transparent;
            background: #eff2f5;
            padding: max(17.5px, 1.8vw);
            border-radius: 10px;
            display: flex;
            align-items: center;
            gap: max(8.5px, 0.7vw);
            flex-wrap: wrap;
            .react-tabs__tab {
              width: 9%;
              align-items: stretch;
              padding: max(13.5px, 1vw) 0;
              background: #fff;
              border-radius: 10px;
              cursor: pointer;

              @media (max-width: 1400px) {
                width: 12%;
              }

              @media (max-width: 1080px) {
                width: 16%;
              }

              @media (max-width: 400px) {
                width: 45% !important;
              }
            }
          }

          .tab-panel-wrapper {
            margin-top: max(17.5px, 2vw);
            display: flex;
            align-items: baseline;
            .tab-panel-left {
              width: 30%;
              h5 {
                font-size: max(15px, 0.97vw);
                font-weight: 700;
              }
            }
            .tab-panel-right {
              width: 70%;
              display: flex;
              flex-direction: column;
              gap: max(12.5px, 0.8vw);
              .treatment-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;
                border-bottom: 1px solid #dbdbdb;
                .treatment-info {
                  font-size: max(12.5px, 0.8vw);
                  h6 {
                    font-weight: 700;
                    font-size: max(12.5px, 0.8vw);
                  }
                  p {
                    color: #565658;
                  }
                }

                // .choose-btn{
                //     margin-right: 7.5%;
                // }
              }
            }
          }
        }

        .react-tabs .react-tabs__tab--selected {
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
          .tab-wrapper {
            .icon {
              filter: invert(48%) sepia(98%) saturate(2134%) hue-rotate(324deg) brightness(99%) contrast(104%);
            }
            h5,
            .icon1 {
              color: #ff5758;
            }
          }
        }

        .tab-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .service_img_source {
            background-position: center !important;
            width: 100% !important;
            background-size: contain !important;
            background-repeat: no-repeat !important;
          }

          .icon1 {
            font-size: max(22px, 1.3vw);
          }

          .icon {
            height: max(25px, 1.7vw);
            margin-bottom: max(8px, 0.5vw);
          }

          h5 {
            font-size: max(11.2px, 0.86vw);
            font-weight: lighter;
            margin: 0 !important;
          }
        }
      }
    }

    // .reviews-section {
    //   padding: max(17.5px, 1.8vw) 0;

    //   .review-title {
    //     font-size: max(14px, 1vw);
    //     font-weight: 700;
    //     margin-bottom: max(17.5px, 2vw);
    //   }

    //   .reviews-info {
    //     display: flex;
    //     align-items: center;
    //     .left {
    //       width: 30%;
    //       display: flex;
    //       align-items: center;
    //       justify-content: start;
    //       .review-info {
    //         display: flex;
    //         align-items: center;

    //         h1 {
    //           font-size: max(22.5px, 2.3vw);
    //           font-weight: 700;
    //           margin-right: max(10px, 0.8vw);
    //           color: #ffae00;
    //         }

    //         .block {
    //           display: flex;
    //           flex-direction: column;
    //           gap: 3px;

    //           p {
    //             font-size: max(13px, 0.66vw);
    //             color: #9f9e9e;
    //             margin-left: 2.5px;
    //           }
    //         }
    //       }
    //     }

    //     .right {
    //       width: 70%;
    //       display: flex;
    //       gap: max(14px, 1vw);
    //       display: flex;
    //       align-items: center;
    //       justify-content: start;
    //       .first {
    //         width: 40%;
    //         display: flex;
    //         flex-direction: column;
    //         gap: max(13px, 0.96vw);
    //       }
    //       .second {
    //         width: 40%;
    //         display: flex;
    //         flex-direction: column;
    //         gap: max(13px, 0.96vw);
    //       }

    //       .inline {
    //         display: flex;
    //         h6 {
    //           font-weight: 700;
    //           font-size: max(12.5px, 0.8vw);
    //           margin-right: 8px;
    //         }
    //       }

    //       .line {
    //         border-bottom: 1px solid #dbdbdb;
    //       }
    //     }
    //   }

    //   .reviews {
    //     display: flex;
    //     // align-items: center;
    //     margin-top: max(20.5px, 1vw);

    //     .filter-section {
    //       width: 30%;
    //       display: flex;
    //       flex-direction: column;
    //       gap: max(12px, 1vw);

    //       .filter-card {
    //         background: #fff;
    //         box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
    //         border-radius: 10px;
    //         width: 80%;
    //         padding: max(12px, 1vw);

    //         p {
    //           font-weight: lighter;
    //           color: #363c45;
    //           font-size: max(12.5px, 0.85vw);
    //         }

    //         select {
    //           color: #c9cdd5;
    //           width: 100%;
    //           padding: max(5px, 0.5vw);
    //           border-radius: 5px;
    //           border: 1px solid #c9cdd5;
    //           margin: max(10.5px, 0.8vw) 0;
    //           font-size: max(12.5px, 0.85vw);
    //         }

    //         .ratings {
    //           margin-top: max(10.5px, 0.8vw);

    //           .inline {
    //             display: flex;
    //             align-items: center;
    //             .checkbox {
    //               svg {
    //                 fill: #c9cdd5;
    //                 width: max(15px, 1.6vw);
    //                 height: max(15px, 1.6vw);
    //               }
    //             }
    //           }
    //         }
    //       }

    //       .verified {
    //         background: #fff;
    //         box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
    //         border-radius: 10px;
    //         padding: max(12px, 1vw);
    //         width: 80%;
    //         display: flex;
    //         align-items: center;

    //         svg {
    //           font-size: max(13.5px, 1.3vw);
    //           color: #ff5758;
    //           margin-right: 5px;
    //         }

    //         h5 {
    //           margin-bottom: 0 !important;
    //           font-size: max(12.5px, 0.85vw);
    //         }
    //       }
    //     }

    //     .comments-section {
    //       width: 70%;
    //       padding: max(22.5px, 2.3vw) 0;
    //       display: flex;
    //       flex-direction: column;
    //       gap: max(14px, 1.7vw);
    //       border-top: 1px solid #c9cdd5;

    //       .comment {
    //         border-bottom: 1px solid #c9cdd5;
    //         padding-bottom: max(12px, 1vw);
    //         h6 {
    //           font-size: max(12.5px, 0.85vw);
    //           margin: max(10.5px, 0.8vw) 0;
    //         }

    //         .row1 {
    //           display: flex;
    //           align-items: center;
    //           gap: 10px;
    //           // margin-bottom: max(10.5px,.8vw);
    //           p {
    //             color: #9fb0ce;
    //             font-size: max(11px, 0.75vw);
    //           }

    //           .treatment {
    //             color: #ff5758;
    //             font-size: max(11.5px, 0.68vw);
    //             padding: 4px 10px;
    //             background: #fff;
    //             border: 1px solid #c6c6c6;
    //             border-radius: 12px;
    //           }
    //         }

    //         .short-line {
    //           height: 1px;
    //           width: 3%;
    //           background: #d5d6d7;
    //           margin-top: 5px;
    //           margin-bottom: max(10.5px, 0.8vw);
    //         }

    //         .row2 {
    //           display: flex;
    //           align-items: baseline;
    //           gap: 10px;

    //           .client {
    //             display: flex;

    //             h5 {
    //               font-size: max(11px, 0.75vw);
    //               margin-right: 5px;
    //             }

    //             svg {
    //               font-size: max(10.5px, 0.8vw);
    //               color: #ff5758;
    //               margin-right: 5px;
    //             }
    //           }
    //           p {
    //             font-size: max(11px, 0.7vw);
    //             color: #9fb5d3;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // .the-team {
    //   padding: max(17.5px, 1.8vw) 0;
    //   border-top: 3px solid #ff5758;
    //   border-bottom: 3px solid #ff5758;

    //   .react-tabs {
    //     display: flex;
    //     align-items: baseline;
    //     justify-content: space-between;
    //     width: 100%;
    //     // border: 1px solid magenta;

    //     .left,
    //     .right {
    //       h5 {
    //         font-size: max(14px, 1vw);
    //         font-weight: 700;
    //         margin-bottom: max(13.5px, 1.3vw);
    //       }
    //     }
    //     .left {
    //       // border: 1px solid blue;
    //       width: 45%;

    //       .react-tabs__tab-list {
    //         display: flex;
    //         flex-direction: column;
    //         padding-left: 0;
    //         .react-tabs__tab {
    //           border-bottom: 1px solid #dbdbdb;
    //           padding: max(14.5px, 1vw) 0;
    //           cursor: pointer;
    //           .tab-wrapper {
    //             display: flex;
    //             align-items: baseline;
    //             // border-bottom: 1px solid #DBDBDB;
    //             // padding: max(12.5px,.8vw) 0;
    //             .circle {
    //               display: flex;
    //               align-items: center;
    //               justify-content: center;
    //               border-radius: 50%;
    //               height: max(35px, 2.25vw);
    //               width: max(35px, 2.25vw);
    //               background: #ff5758;
    //               color: #fff;
    //               margin-right: 12px;
    //             }

    //             h6 {
    //               &:first-letter {
    //                 text-transform: uppercase;
    //               }
    //               font-weight: 700;
    //               font-size: max(12.5px, 0.8vw);
    //             }
    //           }
    //         }

    //         .react-tabs__tab--selected {
    //           .tab-wrapper {
    //             border-left: 3px solid #ff5758;
    //             .circle {
    //               margin-left: 12px;
    //             }
    //           }
    //         }
    //       }
    //     }
    //     .right {
    //       // border: 1px solid green;
    //       width: 45%;
    //       .react-tabs__tab-panel {
    //         .tab-panel-wrapper {
    //           display: flex;
    //           align-items: center;
    //           justify-content: center;
    //           background: #fff;
    //           // width: 15%;
    //           width: fit-content;
    //           padding: 4px 15px;
    //           border-radius: 15px;
    //           .icon {
    //             height: max(18px, 1.3vw);
    //             margin-right: 8px;
    //             filter: invert(29%) sepia(7%) saturate(0%) hue-rotate(66deg) brightness(96%) contrast(89%);
    //           }
    //           .icon1 {
    //             content: url("../../assets/images/makeUp.svg");
    //           }
    //           .icon2 {
    //             content: url("../../assets/images/hairdryer-icon.svg");
    //           }
    //           .icon3 {
    //             content: url("../../assets/images/nailsIcon.svg");
    //           }
    //           p {
    //             color: #757575;
    //             font-size: max(13px, 0.66vw);
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    .about-salon {
      padding: max(17.5px, 1.8vw) 0;

      h5 {
        font-size: max(14px, 1vw);
        font-weight: 700;
        margin-bottom: max(17.5px, 2vw) !important;
      }

      .location {
        display: flex;

        .map-container {
          width: 50%;
        }

        .address {
          width: 50%;
          background: #fff;
          padding: max(14px, 1vw) max(15.5px, 1.6vw);

          h6 {
            font-weight: 700;
            font-size: max(12.5px, 0.8vw);
          }

          p {
            color: #757575;
            font-size: max(13px, 0.8vw);

            span {
              margin-right: 5px;
            }
          }
        }
      }

      .salon-schedule {
        display: flex;
        // align-items: center;
        padding: max(17.5px, 1.8vw) 0;
        border-bottom: 1px solid #c9cdd5;

        .text,
        .schedule {
          width: 50%;
        }
        .text {
          p {
            font-size: max(12.5px, 0.85vw);
            color: #757575;
          }
        }
        .schedule {
          display: flex;
          flex-direction: column;
          align-items: end;
          width: 50%;
          @media (max-width: 1200px) {
            gap: max(9px, 0.5vw) !important;
          }
          .schedule-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 45%;
            .day {
              width: 50%;
              display: flex;
              align-items: center;
              svg {
                color: #3f7652;
                font-size: max(20px, 1.6vw);
              }
              h6 {
                font-size: max(13px, 0.8vw);
                margin-bottom: 0;
                color: #484848;
              }
            }
            .dayoff {
              svg,
              h6 {
                color: #8d8d8d;
              }
            }
            .time {
              width: 30%;
              display: flex;
              justify-content: end;
              p {
                color: #484848;
                font-size: max(13px, 0.8vw);
              }
              .dayoff {
                color: #8d8d8d;
              }
            }

            @media (max-width: 1511px) {
              width: 50%;
              .time {
                width: 50%;
              }
            }

            @media (max-width: 1200px) {
              width: 60%;

              .time {
                width: 50%;
              }
            }
          }
        }
      }
      .back {
        padding-top: max(15.5px, 1vw);
        display: flex;
        align-items: center;
        justify-content: start;
        a,
        span,
        p {
          color: #484848;
          transition: 0.3s ease-in-out;
        }

        span {
          margin: 0 5px;
        }

        a:hover {
          color: #ff5758;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .salon-layout .salon-inner {
    .reviews-section {
      .reviews-info {
        .left {
          width: 40%;
        }
        .right {
          width: 60%;
        }
      }

      .reviews {
        .filter-section {
          width: 40%;
        }
        .comments-section {
          width: 60%;
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .salon-layout {
    .salon-inner {
      .popular-services {
        .left {
          width: 35%;
        }
        .services {
          width: 65%;
        }
      }

      .reviews-section {
        .reviews-info {
          .right {
            .first,
            .second {
              width: 50%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .salon-layout .salon-inner {
    .banner {
      .slider-wrapper .slideshow-container .slider-img {
        height: 259px;
      }
    }
    .browse-services {
      .tabs-container {
        .react-tabs {
          .react-tabs__tab-list {
            justify-content: center;
            .react-tabs__tab {
              flex: 0 0 calc(50% - 15px);
            }
          }
        }
      }
      .tab_group_layout {
        justify-content: center !important;
      }
    }
    .about-salon {
      .salon-schedule {
        flex-direction: column;
        gap: 20px;
        .text {
          width: 100%;
          text-align: center;
        }
        .schedule {
          width: 100%;
          align-items: center;
          .schedule-info {
            width: 40%;
          }
        }
      }
    }
  }
}
// }}

//     .reviews-section {
//       .reviews-info {
//         flex-direction: column;
//         gap: max(14px, 1.7vw);
//         .left,
//         .right {
//           width: 100%;
//         }

//         .left,
//         .right {
//           justify-content: center;
//         }
//       }

//       .reviews {
//         .filter-section {
//           width: 50%;
//           .filter-card,
//           .verified {
//             width: 92%;
//           }
//         }
//         .comments-section {
//           width: 50%;
//         }
//       }
//     }

//     .about-salon {
//       .salon-schedule {
//         flex-direction: column;
//         gap: 20px;
//         .text {
//           width: 100%;
//           text-align: center;
//         }
//         .schedule {
//           width: 100%;
//           align-items: center;
//           .schedule-info {
//             width: 40%;
//           }
//         }
//       }
//     }
//   }
// }

@media (max-width: 680px) {
  .salon-layout {
    .salon-inner {
      .popular-services {
        .left {
          width: 45%;
        }
        .services {
          width: 55%;
        }
      }

      .reviews-section {
        .reviews-info {
          flex-direction: column;
          gap: max(22px, 2vw);
          .left,
          .right {
            width: 100%;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .salon-layout {
    .salon-inner {
      .popular-services {
        flex-direction: column;
        .left,
        .services {
          width: 100%;
        }
        .left {
          text-align: center;
          margin-bottom: max(12.5px, 0.8vw);
        }
      }
      .browse-services {
        h5 {
          text-align: center;
        }
        .tabs-container {
          .tabs_listing {
            border: 1px solid transparent;
            background: #eff2f5;
            padding: max(17.5px, 1.8vw);
            border-radius: 10px;
            display: flex;
            align-items: center;
            gap: max(8.5px, 0.7vw);
          }
          .react-tabs {
            .tab-panel-wrapper {
              flex-direction: column;
              .tab-panel-left,
              .tab-panel-right {
                width: 100%;
              }

              .tab-panel-left {
                text-align: center;
                margin-bottom: max(12.5px, 0.8vw);
              }
            }
          }
        }
      }

      // .reviews-section {
      //   h5 {
      //     text-align: center;
      //   }
      //   .reviews {
      //     flex-direction: column;
      //     .filter-section {
      //       width: 100%;
      //       .filter-card,
      //       .verified {
      //         width: 100%;
      //       }
      //     }
      //     .comments-section {
      //       width: 100%;
      //     }
      //   }
      // }

      .about-salon {
        .location {
          flex-direction: column;
          .map-container,
          .address {
            width: 100%;
          }
        }
        .salon-schedule .schedule .schedule-info {
          width: 65%;
        }
      }
    }
  }
}

// @media (max-width: 460px) {
//   .salon-layout .salon-inner {
//     .reviews-section .reviews-info {
//       .right {
//         flex-direction: column;
//         .first,
//         .second {
//           width: 100%;
//         }

//         .inline {
//           justify-content: center;
//         }
//       }
//     }
//   }
// }

@media (max-width: 380px) {
  .salon-layout .salon-inner {
    // .browse-services .tabs-container .react-tabs .react-tabs__tab-list{
    //   flex-wrap: wrap;
    // }
    .about-salon {
      .salon-schedule .schedule .schedule-info {
        width: 85%;
      }
    }
  }
}

@-webkit-keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.wobbling-link:hover svg {
  -webkit-animation-name: hvr-icon-wobble-horizontal;
  animation-name: hvr-icon-wobble-horizontal;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
