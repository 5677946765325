.search-result-layout {
    margin: 2% 4.5%;
    position: relative;

    .search-result-banner{
        // background: url('../../assets/images/search-result-banner.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: max(75px, 4.7%) 9.7%;

        .content{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid transparent;
            border-radius: 10px;
            .left{
                width: 50%;
    
                .input-container{
                    display: flex;
                    align-items: center;
                    border: 1px solid #C7C7C7;
                    background: #fff;
                    border-radius: 10px;
                    padding: 0px max(3px, 0.2vw);
                    margin-bottom: max(6px, 0.3vw);
                    width: 282px;
                    &:focus-within {
                        .search-icon {
                            filter: invert(48%) sepia(98%) saturate(2134%) hue-rotate(324deg) brightness(99%) contrast(104%);
                        }
                        border: 1px solid #FF5758;
                        ::-webkit-input-placeholder{
                            color: #FF5758;
                        }
                    }
                  
    
                    @media(max-width: 370px){
                        width: 100%;
                    }
    
                    .search-icon{
                        content: url('../..//assets/images/search-icon.svg');
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0px 0 0 max(3px, 0.2vw);
                        height: max(17px, 1.4vw);
                        filter: invert(96%) sepia(0%) saturate(878%) hue-rotate(69deg) brightness(91%) contrast(98%);
                    }
    
                    input {
                        background: none;
                        border: 0;
                        flex: 1 1 0%;
                        font-size: max(12px,.7vw);
                        height: max(30px, 2.2vw);
                        padding: 0px max(3px, 0.2vw);
                    }
                }
    
                .location-date-wrapper {
                    display: flex;
                    align-items: center;
                   
                    span{
                        background-color: #eff2f5;
                        border: 1px solid #aeb6bd;
                        border-radius: 30px;
                        font-size: max(10px,.25vw);
                        width: fit-content;
                        padding: 2.5px 8px;
                        color: #aeb6bd;
                        text-transform: uppercase;
                    }
    
                    :first-child{
                        margin-right: 7px;
                    }

                    @media(max-width: 400px) {
                        flex-direction: column;
                        gap: 5px;
                    }
                }
    
            }
    
            .right{
                padding-left: 13%;
                width: 50%;

                @media(max-width: 1156px){
                    padding-left: 8%;
                }
    
                h4{
                    color: #fff;
                    font-weight: 700;
                    font-size: max(22.5px, .75vw);
                }
            }
        }
    }

    .search-result-content {
        padding: max(25px, 1.5%) 9.7%;
        background-color: #F7F9FA;

        @media(max-width: 500px){
            padding: max(25px, 1.5%) 8.5% max(75px, 4.7%);
        }

        .top{
            margin-bottom: 20px;
            p{
                color: #8D9095;
                font-size: max(12.5px,.8vw);
                margin-right: 5px;
            }
        }

        .salons-wrapper {
            position: relative;
            .salons{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                position: relative;
                gap: max(15px,1.4vw);
                

                .salon-content{
                    background-color: #FFFFFF;
                    border-radius: 10px;
                    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
                    padding: max(17.5px,1.5vw);
                    width: 49%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    overflow: hidden;
                    // height: 596px;

                    .first-row{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .slider{
                            width: 50%;
                        }
                        .info{
                            width: 45%;
                            h5{
                                font-weight: 700;
                                font-size: max(15px, .97vw);
                            }
                            .rating{
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center !important;
                                gap: 1px;
                                color: #FFAE00;
                                margin: max(9px,0.75vw) 0;
                                font-size: max(15px,0.9vw);

                                .rating-number{
                                    margin-right: 5px;
                                    font-weight: 700;
                                }

                                .star-icon{
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 4.5px;
                                }
                            }

                            p{
                                color: #8D9095;
                                font-size: max(12.5px,.8vw);
                            }
                        }
                    }

                    .second-row{
                        border-top: 1px solid #DBDBDB;
                        border-bottom: 1px solid #DBDBDB;
                        margin: max(12.5px,.8vw) 0;
                        padding: max(15.5px,1.3vw) 0;
                        display: flex;
                        flex-direction: column;
                        flex: 1 1;
                        gap: max(12.5px,.8vw);

                        .treatment-wrapper{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            @media(max-width: 355px){
                                flex-direction: column;
                                justify-content: center;
                                text-align: center;
                            }

                            .treatment-info{
                                font-size: max(12.5px,.8vw);
                                h6{
                                    font-weight: 700;
                                }
                                p{
                                    color: #565658;
                                }
                            }

                            .choose-btn{
                                margin-right: 7.5%;
                            }
                        }
                    }

                    .third-row{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: max(10.5px,.5vw) 0;

                        a{
                            color: #4682B4;
                            font-weight: 700;
                            font-size: max(15px, .97vw);
                        }

                        .arrow-down{
                            content: url('../../assets/images/arrow-down.svg');
                            filter: invert(43%) sepia(91%) saturate(322%) hue-rotate(164deg) brightness(92%) contrast(83%);
                        }
                    }
                }
            }
        }
        .pagination-container{
            margin-top: max(30px, 1.8vw);
            display: flex;
            align-items: center;
            justify-content: center;
           .pagination{
            margin: 0;
            gap: 15px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            li{
                // border: 1px solid #ff575770;
                border-radius: 50%;
                height: max(35px, 2.25vw);
                width: max(35px, 2.25vw);
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
               svg {
                color: #000;
               }
            }

            .previous a,
            .next a{
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .active{
                background-color: #FF5758 !important;
                color: #fff;
            }
           }
        }
    }
}

@media(max-width: 1080px){
    .search-result-layout{
        .search-result-content{
            padding: max(20px, 3.7%) 5%;
        }
    }
}

@media(max-width: 950px){
    .search-result-layout{
        .search-result-content{
           padding: 2.5%;
        }
    }
}

@media(max-width: 880px){
    .search-result-layout{
        .search-result-content{
            padding: 5% 2.5%;
           .salons-wrapper{
            .salons{
                gap: max(25px,1.4vw);
                .salon-content{
                    width: 48%;
                    .first-row{
                        flex-direction: column-reverse;
                        gap: 15px;
                        .slider,
                        .info
                        {
                            width: 100%;
                        }
                        .info{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
           }
        }
    }
}

@media(max-width: 767px){
    .search-result-layout{
        .search-result-banner{

           .content{
            padding: max(12.5px, 1vw);
            flex-direction: column-reverse;

            .right{
                padding-left: 0;
                margin-bottom: max(25px, 2vw);
                text-align: center;
            }

            .left,
            .right{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
           }
        }

        .search-result-content{
            .top{
                flex-direction: column;
                margin-bottom: max(17.5px,1.5vw);
                p{
                    margin-right: 0;
                    text-align: center;
                }
            }
            .salons-wrapper .salons{
                .salon-content{
                    width: 80%;
                    margin: 0 auto;
                    .first-row{
                       display: flex;
                       flex-direction: row;
                        .slider{
                            width: 50%;
                        }
                        .info{
                            width: 45%;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 500px){
    .search-result-layout{
        .search-result-content{
            .salons-wrapper{
                .salons{
                    .salon-content{
                        width: 95%;
                        .first-row{
                            flex-direction: column-reverse;
                            gap: 15px;
                            .slider,
                            .info
                            {
                                width: 100%;
                            }
                            .info{
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                            }
                        }

                        .second-row{
                            .treatment-wrapper{
                                .choose-btn{
                                    margin-right: 0;
                                }
                            }
                        }

                        .third-row{
                            .arrow-down{
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}





  
   
