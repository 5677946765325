.user-profile-layout {
  margin: 2% 4.5%;
  .react-tabs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .profile-sidebar {
      padding: max(25.5px, 1.5vw);
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
      border-radius: 10px;
      width: 20%;
      // height: 50vh;
      height: auto;
      background: #f7f9fa;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        font-size: max(22.5px, 2vw);
        text-align: center;
        margin-bottom: max(30.5px, 3vw);
      }

      .react-tabs__tab-list {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 7px;
        height: 100%;
        .react-tabs__tab {
          cursor: pointer;
          h5 {
            font-size: max(16px, 0.96vw);
            display: flex;
            align-items: center;
            justify-content: start;
            svg {
              display: none;
            }
          }
        }
      }

      .react-tabs__tab-list .react-tabs__tab--selected {
        h5 {
          color: #ff5758;
          svg {
            display: block;
            margin-right: 5px;
          }
        }
      }
    }

    .profile-content {
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
      border-radius: 10px;
      width: 78%;
      height: auto;
      background: #f7f9fa;
      padding: max(20.5px, 1vw) max(25.5px, 1.5vw);

      .react-tabs__tab-panel {
        .user-info-container {
          width: 100%;
          .user-info {
            .edit_profile_image {
              width: fit-content;
              word-break: break-all;
              padding: 5px;
              // margin-top: 30px;
              margin: 30px auto 0 auto;
              border: solid 2px #36b2b0;
              color: #36b2b0;
              border-radius: 12px;
              // position: relative;
              // bottom: -8.5rem;
              &:hover {
                transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                scale: 1.1;
              }
            }
            display: flex;
            align-items: center;
            width: 100%;
            margin-left: 0;
            .user-img-container {
              // width: 243px;
              // max-height: 300px;
              // padding: max(6px, 0.6vw);
              // // background: #e6e9ec;
              // border-radius: 10px;

              .inner-img {
                /* Style the container for the file input */
                // .file-input-container {
                //   position: relative;
                //   // width: 200px;
                //   padding: 10px;
                //   margin-top: 10px;
                //   background-color: #f0f0f0;
                //   border: 1px solid #ccc;
                //   border-radius: 5px;
                //   cursor: pointer !important;
                // }

                // /* Style the input element to make it transparent */
                // .file-input {
                //   position: absolute;
                //   top: 0;
                //   left: 0;
                //   width: 100%;
                //   height: 100%;
                //   opacity: 0;
                //   cursor: pointer;
                // }

                // /* Style the text inside the container */
                // .file-input-text {
                //   text-align: center;
                //   cursor: pointer;
                // }
                // border-radius: 10px;
                // width: 100%;
                // overflow: hidden;

                // .img {
                //   content: url("../../assets/images/user-solid.svg ");
                //   transition: 0.3s ease-in-out;
                //   width: 100%;
                // }

                .profile-pic {
                  width: 200px;
                  max-height: 200px;
                  display: inline-block;
                }

                .file-upload {
                  display: none;
                }
                .circle {
                  // border-radius: 100% !important;
                  // // overflow: hidden;
                  // // width: 128px;
                  // // height: 128px;
                  // border: 2px solid rgba(255, 255, 255, 0.2);
                  // // position: absolute;
                  // // top: 72px;
                  display: flex;
                  align-items: center;
                  object-fit: contain;
                  justify-content: center;
                  border-radius: 100%;
                  overflow: hidden;
                  width: 250px;
                  height: 250px;
                  border: 20px solid #e6e9ec;
                  // position: absolute;
                  // top: 72px;
                }
                img {
                  max-width: 100%;
                  height: auto;
                }
                .p-image {
                  width: 100%;
                  position: relative;
                  margin-top: -1.6rem;
                  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                }
                .p-image:hover {
                  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                }
                .upload-button {
                  font-size: 1.2em;
                  top: -3.5rem;
                  right: 2rem;
                  border: solid 2px #e6e9ec;
                  padding: 5px;
                  border-radius: 100%;
                  background-color: #e6e9ec;
                }

                .upload-button:hover {
                  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                  color: #999;
                  scale: 1.1;
                  border: solid 2px #999;
                  cursor: pointer;
                }
              }

              // &:hover .img {
              //   transform: scale(1.1);
              // }
            }

            .main-info {
              // width: 75%;
              padding: max(25.5px, 1.5vw);

              h2 {
                font-size: max(21.5px, 1.8vw);
                margin-bottom: max(20px, 1.2vw);
              }

              .info-wrapper {
                // border: 1px solid red;
                display: flex;
                flex-direction: column;
                gap: max(9.5px, 0.8vw);
                .info-item {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  h6 {
                    margin-bottom: 0;
                    font-size: max(14px, 1vw);
                  }

                  p {
                    color: #565658;
                    font-size: max(13px, 0.9vw);
                  }
                }
              }
            }
          }
        }

        .appointments-container {
          width: 100%;
          h2 {
            font-size: max(21.5px, 1.8vw);
            margin-bottom: max(20px, 1vw);
            text-align: center;
          }
          h3 {
            width: 70%;
            margin: auto;
            background-color: #f8bbbc;
            border-radius: 10px;
            line-height: 38px;
            font-size: max(15.5px, 1.1vw) !important;
            margin-bottom: max(20px, 1vw);
            text-align: center;
          }
          .table-container {
            .profile-table {
              overflow: hidden;
              .titles {
                border-bottom: 1px solid rgb(212, 212, 212);
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: max(10px, 1vw) max(15px, 1.5vw);
                .title {
                  width: 20%;
                  font-size: max(15px, 1vw);
                  text-align: center;
                  color: #707070;
                }
              }
              .data-container {
                margin: 8px 0;
                .data-wrapper {
                  display: flex;
                  width: 100%;
                  padding: max(7.5px, 0.8vw) max(15px, 1.5vw);
                  &:hover {
                    background: #fae4e4;
                    border-radius: 5px;
                  }
                  .anuluar {
                    color: red;
                  }
                  .planifikuar {
                    color: orange;
                  }
                  .përfunduar {
                    color: green;
                  }

                  .delayed {
                    color: orange;
                  }

                  .confirmed {
                    color: green;
                  }
                  .data {
                    width: 20%;
                    font-size: max(13px, 0.9vw);
                    text-align: center;
                  }
                }
              }
            }
          }
        }

        .settings-container {
          width: 100%;
          display: flex;
          margin: 25px 0px;
          // border: 1px solid black;
          h2 {
            font-size: max(21.5px, 1.8vw);
            margin-bottom: max(20px, 1vw);
            text-align: center;
          }
          .first-form {
            width: 35%;
            margin-bottom: 25px;
            margin-top: 25px;
            button {
              margin-top: 15px;
            }
          }
          .second-form {
            width: 35%;
            margin-bottom: 25px;
            margin-top: 25px;
          }
          form {
            width: 50%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            gap: max(9.5px, 0.8vw);
            .input-wrapper {
              width: 100%;
              p {
                font-size: max(13.5px, 1vw);
                margin-bottom: 5px;
              }
              input {
                width: 100%;
                padding: 0 max(10px, 0.5vw);
                height: max(35px, 2vw);
                border: 1px solid #e2e2e2;
                border-radius: 10px;
                font-size: max(13px, 0.9vw);
                &:focus-within {
                  border: 1px solid #ff5758;
                  &::placeholder {
                    color: #ff5758 !important;
                  }
                }
              }
            }
            button {
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .user-profile-layout {
    .react-tabs {
      .profile-sidebar {
        width: 30%;
      }
      .profile-content {
        width: 68%;
        .react-tabs__tab-panel {
          .user-info-container {
            .user-info {
              .user-img-container {
                // width: 40%;
              }

              .main-info {
                // width: 60%;
              }
            }
          }

          .appointments-container {
            .table-container {
              overflow-x: scroll;
              &::-webkit-scrollbar {
                height: 12px;
              }
              &::-webkit-scrollbar-track {
                background: #e2deda;
                border-radius: 1px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: #f8bbbc;
                border-radius: 1px;
              }
              .profile-table {
                width: 800px;
                margin: 0 auto;
              }
            }
          }

          .settings-container {
            form {
              width: 70%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 760px) {
  .user-profile-layout {
    .react-tabs {
      .profile-content {
        width: 100% !important;
        .first-form,
        .second-form {
          width: 45% !important;
        }
      }
    }
  }
}

@media (max-width: 620px) {
  .user-profile-layout {
    .react-tabs {
      .profile-content {
        width: 100% !important;
        .settings-container {
          flex-direction: column;

          .first-form,
          .second-form {
            width: 100% !important;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .user-profile-layout {
    .react-tabs {
      flex-direction: column;
      gap: 20px;
      .react-tabs__tab-list {
        flex-direction: row !important;
        flex-wrap: wrap;
        gap: 25px !important;
      }
      .profile-sidebar,
      .profile-content {
        width: 100% !important;
        border-radius: 0;
      }

      .profile-sidebar {
        height: auto !important;
      }

      .profile-content {
        .user-info-container {
          width: 80% !important;
          margin: 0 auto;
          // border: 1px solid red;
          .main-info {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .user-profile-layout .react-tabs .profile-content {
    .user-info-container {
      width: 90% !important;
    }

    .settings-container {
      form {
        width: 80% !important;
      }
    }
  }
}

@media (max-width: 500px) {
  .user-profile-layout .react-tabs .profile-content {
    .user-info-container {
      width: 100% !important;
      // .user-info{
      //   flex-direction: column;
      //   .user-img-container,
      //   .main-info{
      //     width: 60% !important;
      //   }
      // }
      .user-info {
        h2 {
          margin-bottom: 10px !important;
        }
        .info-wrapper {
          gap: 5px !important;
        }
      }
    }
  }
}

@media (max-width: 440px) {
  .user-profile-layout .react-tabs .profile-content {
    .user-info-container {
      .user-info {
        flex-direction: column;
        .user-img-container {
          width: 95% !important;
        }
        .main-info {
          width: 100% !important;
        }
      }
    }

    .settings-container {
      form {
        width: 95% !important;
      }
    }
  }
}

@media (max-width: 360px) {
  .user-profile-layout {
    .react-tabs {
      .react-tabs__tab-list {
        flex-direction: column !important;
        flex-wrap: wrap;
        gap: 10px !important;
      }
    }
  }
}
