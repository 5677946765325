.footer-layout {
  // position: absolute;
  // bottom: 0;
  background-color: #fff;
  width: 100%;
  // height: 200px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: max(4.9vw, 55px) 18px;
}



