*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  outline: none;
}

html,
body {
  height: 100%;
}

@font-face {
  font-family: "Quicksand-VariableFont";
  src:
    local("Quicksand-VariableFont_wght.ttf"),
    url("./data/fonts/Quicksand-SemiBold.ttf") format("truetype");
}

body {
  background-color: rgb(239, 242, 245);
  font-family: "Quicksand-VariableFont" !important;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #e2deda;
  border-radius: 1px;
}

body::-webkit-scrollbar-thumb {
  background-color: #f8bbbc;
  border-radius: 1px;
}
input[type="number"] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button,
  &::-webkit-clear-button {
    display: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
  }
}
#root {
  display: flex;
  flex-direction: column;
  // height: 100%;
  min-height: 100%;
}

.pages-container {
  flex-grow: 1;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

ul li {
  list-style-type: none !important;
}

.social-medias {
  a {
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
}

.choose-btn,
.homepage_search_input,
.auth-btn,
.update-btn {
  margin-top: 2%;
  border: none;
  padding: max(4px, 0.1vw) max(15px, 1.5vw);
  border-radius: 10px;
  font-size: max(12.5px, 0.8vw);
  background: #ff5758;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: fit-content;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  color: #fff;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  cursor: pointer;
}

.choose-btn::before,
.homepage_search_input::before,
.auth-btn::before,
.update-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #e64e4e;
  border-radius: 10px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.choose-btn:hover::before,
.choose-btn:focus::before,
.choose-btn:active::before,
.homepage_search_input:hover::before,
.homepage_search_input:focus::before,
.homepage_search_input:active::before,
.auth-btn:hover::before,
.auth-btn:focus::before,
.auth-btn:active::before,
.update-btn:hover::before,
.update-btn:focus::before,
.update-btn:active::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.tracking-in-expand {
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.tracking-in-contract {
  animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

.tracking-in-expand-fwd-bottom {
  animation: tracking-in-expand-fwd-bottom 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

@keyframes tracking-in-expand-fwd-bottom {
  0% {
    letter-spacing: -0.5em;
    transform: translateZ(-700px) translateY(500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .homepage-layout,
  .search-result-layout,
  .salon-layout,
  .checkout-layout,
  .checkout2-layout,
  .checkout3-layout,
  .user-profile-layout {
    margin: 10% 0 0 !important;
  }
}

@media (max-width: 553px) {
  .homepage-layout,
  .search-result-layout,
  .salon-layout,
  .checkout-layout,
  .checkout2-layout,
  .checkout3-layout,
  .user-profile-layout {
    margin: 15% 0 0 !important;
  }
}

@media (max-width: 400px) {
  .homepage-layout,
  .search-result-layout,
  .salon-layout,
  .checkout-layout,
  .checkout2-layout,
  .checkout3-layout,
  .user-profile-layout {
    margin: 22% 0 0 !important;
    padding-bottom: 12% !important;
  }
}

@media (max-width: 915px) {
  [data-aos] {
    opacity: 1 !important;
    transform: none !important;
    transition: none !important;
  }
}
