.yarl__slide_image {
    -webkit-touch-callout: none;
    max-width: 100% !important;
    max-height: 100% !important;
    width: 80% !important;
    height: 80% !important;
    -o-object-fit: contain;
    object-fit: contain;
    touch-action: var(--yarl__controller_touch_action,none);
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none;

    @media(max-width: 1024px){
        height: auto !important;
    }
}

.yarl__container {
    background-color: rgba(0,0,0,.9) !important;
}

 .yarl__flex_center{
    flex-direction: column !important;
}

.yarl__slide_captions_container {
    background: none !important;
    position: relative !important;
}

.yarl__slide_title {
    color: var(--yarl__slide_title_color,#fff);
}

.yarl__slide_description {
    text-align: var(--yarl__slide_description_text_align,center) !important;
}

.slideshow-container{
    .slider-img{
        width: 100%;
        height: 159px;
        cursor: pointer;
        position: relative;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .slider-text{
            position: absolute;
            z-index: 111;
            bottom: 0;
            width: 100%;
            min-height: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: hsl(0, 0%, 100%, .7);
            padding: max(8.5px, 0.55vw) max(10.5px, 0.65vw);
            transition: 0.3s ease-in-out;
            opacity: 0;
            h5{
                margin: 0;
                font-size: max(13px, 0.85vw);
            }
            span{
                font-size: max(13px, 0.66vw);
            }
        }
    }

    .react-slideshow-container{
        button{
            background: none;
            width: 80px;
            height: 80px;
            transition: 0.3s ease-in-out;
            svg{
               fill: #ffffff8d;
               width:max(27.5px,2.2vw);
               height: max(27.5px,2.2vw);
            }
            opacity: 0;
        }
        button:hover,
        button:focus{
            background: none;
            svg{
                fill: #FFFFFF;
            }
        }
    }
}

.slideshow-container:hover{
    .slider-text{
        opacity: 1;
    }
    .react-slideshow-container{
        button{
            opacity: 1;
        }
    }
}

